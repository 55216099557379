import React from 'react';

import { BrowserRouter, Route, Routes } from 'react-router-dom';

import "./assets/css/styles.css"; 
import Wrapper from './Wrapper';
import AssignmentManager from './assignmentManager/AssignmentManager';


function App(props) {
  return (
    <BrowserRouter
			props={props}
			basename={process.env.PUBLIC_URL}
		>
			<Routes>
				<Route
					path='/'
					element={<AssignmentManager />}
				></Route>
				<Route
					path='/assignmentOverview'
					element={<Wrapper type='overview' />}
				></Route>
				<Route path='/assignment'
					element={<Wrapper type='assignment'/>}
					/>
					<Route path='/assignmentScroll'
					element={<Wrapper type='assignment' scroll={true}/>}
					/>
				
			</Routes>
		</BrowserRouter>

  );
}

export default App;
