import React from "react";

const SpfElementImage = (props) => {

    return (
        <div className={`spf-rce-element spf-element-image`}>
            <div className="component-container">
                <figure className={`${props.modifier ? props.modifier : ''}`}>
                    <img src={props.data.source} alt={""} />
                    {props.data.caption && <figcaption><p>{props.data.caption}</p></figcaption>}
                </figure>
            </div>
        </div>           
    )
}
export default SpfElementImage