import React from "react";

const SpfRow = (props) => {

    return (
        <div className={`spf-rce-row`}>
            {props.children}
        </div>           
    )
}
export default SpfRow