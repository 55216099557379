import React, {useState} from "react";
import Header from "../shared/Header";
import ListView from "./ListView";

import data from '../assets/data/assignments.json';
import Sidebar from "./Sidebar";

const AssignmentManager = () => {
    const [assignments] = useState(data);
	const [startDate] = useState(new Date());
    return (
        <>
        <Header page="assignments" title="Curriculex"/>
        <div className='body-wrap'>
             <Sidebar active='assignments'/>
            
            <main>
                <div className="wrapper">
                    <h2>Assignments</h2>
                    <div className="section-header">
                        <div className='search-assignments'>
                            <label
                                htmlFor='search-assignments'
                                className='gr-label'
                                aria-label='Search assignments'
                            >
                                <span class='material-icons'>search</span>
                            </label>
                            <select
                                label='Assignment type'
                                value={1}
                            >
                                <option value={1}>All assignment types</option>
                            </select>
                        </div>
                        <div className="actions">
                            <button className="ov-button outlined primary">Create new</button>
                            <div className="toggle-button-group">
                                <button className="toggle-button selected" aria-label="list view" aria-pressed="true"><span className='material-icons'>format_list_bulleted</span></button>
                                <button className="toggle-button" aria-label="calendar view" aria-pressed="false"><span className='material-icons'>calendar_month</span></button>
                            </div>
                        </div>
                    </div>
                    <ListView
                        assignments={assignments}
                        startDate={startDate}
                    />
                </div>
            </main>
        </div>
        </>
    )
}

export default AssignmentManager