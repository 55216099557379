
import OverallScore from "./OverallScore";

const OverviewPageHero = (props) => {

    let completionPercentage = (props.itemsCompleted / props.totalItems) * 100;

    const formatTime = (seconds) => {
        let minutes = Math.floor(seconds / 60);
        let extraSeconds = seconds % 60;
        extraSeconds = extraSeconds < 10 ? "0" + extraSeconds : extraSeconds;

        return minutes
    }

    return(
        <section className={`overview-hero ${props.inView ? '' : 'pinned'}`}>
            {/* <span className="gradient" aria-hidden="true"></span> */}
            <div className="wrapper">
                <div className="card">
                <div className="overview-hero__left">
                    {/* <p className="assignment-type">{props.assighmentType}</p> */}
                    <h1>
                        <span className="assignment-chapter">{props.assignmentChapter}</span>

                        {props.assignmentName}
                    </h1>

                    <ul className="meta-data">
                        <li className="due-date">
                            {/* <span className="label">Due:</span> */}
                            <span className="item-value">{props.dueDate}</span> 
                        </li>
                        <li className="points">
                        {/* <span className="label">Worth:</span> */}
                        <span className="item-value">{props.totalPoints} points available</span>
                        </li>
                        {/* <li><a href="!#">See late policy</a></li> */}
                    </ul>
                    <div className="action-bar">
                    {props.itemsCompleted === props.totalItems ?
                        <button className="gr-btn extra-large">Review</button>
                    : props.itemsCompleted !== 0 ?
                        <button className="gr-btn extra-large" onClick={props.openPlayer}>Continue</button>
                    :
                        <button className="gr-btn extra-large" onClick={props.openPlayer}>Get Started</button>
                    }

                    {/* {props.itemsCompleted > 0 &&
                        <div className="progress-indicator">
                            <span className="progress-info">{props.itemsCompleted} of {props.totalItems} completed</span>
                            <div className="progress">
                                <span style={{"width": completionPercentage + '%'}}></span>
                            </div>
                        </div>
                    } */}
                </div>
                </div>
                

                
                </div>
            </div>
        </section>
    )
}

export default OverviewPageHero;