import React, { useEffect } from "react";
import { useInView } from "react-intersection-observer";

function VirtualScrollChild({height,children, trigger}) {
    const [ref, inView, entry] = useInView({ trackVisibility: true, delay: 750 });
    const style= {
        height: `${height}px`,
        overflow:'hidden'
    };

    useEffect(() => {
        if (entry?.intersectionRect.height > 0) {
            // console.log(entry?.intersectionRect)
            trigger()
            // console.log("trigger)")
            // ref.current.focus()
        }
    },[entry])
    // console.log(entry)
    return (
        <div className="content-part iframe" ref={ref} tabIndex={'-1'} style={{minHeight:'40px'}}>
            {inView ? children : null}
        </div>
    )
}
export default VirtualScrollChild