import React from "react";

const SpfElementText = (props) => {

    const CustomTag = (props) => {
        switch (props.type) {
            case 'h1':
                return <h1 dangerouslySetInnerHTML={{__html: props.children}}/>
            case 'h2':
                return <h2 dangerouslySetInnerHTML={{__html: props.children}}/>
            case 'h3':
                return <h3 dangerouslySetInnerHTML={{__html: props.children}}/>
            case 'h4':
                return <h4 dangerouslySetInnerHTML={{__html: props.children}}/>
            case 'p':
                return <p dangerouslySetInnerHTML={{__html: props.children}} />
            case 'p-strong':
                return <p><strong>{props.children}</strong></p>
            case 'body':
                return <p>{props.children}</p>
            case 'ol':
                return <ol>{props.children.map((item,index) => {return (<li><p dangerouslySetInnerHTML={{__html: item.content}} /></li>)})}</ol>
            case 'ul':
                return <ul>{props.children.map((item,index) => {return (<li><p dangerouslySetInnerHTML={{__html: item.content}} /></li>)})}</ul>
            case 'external-link':
                return <p><a href="#" className="external" dangerouslySetInnerHTML={{__html: props.children}}/></p>
            case 'showhide':
                return <div className="spf-rce-showhide">
                        <span className="">{props.children.showText}</span>
                        <span><svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34" fill="none">
                        <path d="M31.1663 11.3333L16.9997 25.5L2.83301 11.3333L5.34759 8.81875L16.9997 20.4708L28.6518 8.81875L31.1663 11.3333Z" fill="#5F6368"/>
                        </svg></span>
                    </div>
            default:
                return <p>Need to build {props.type}</p>

                // Need to add show hide, icon
        }
    }

    return (
        <div className={`spf-rce-element spf-element-text`}>
            <div className={`${props.modifier ? props.modifier : ''}`} lang="en">
            {props.data.map((item,index) => {
                return (
                    <CustomTag type={item.type}>{item.content}</CustomTag>
                    
                )
            })}
            </div>
            
        </div>           
    )
}
export default SpfElementText