import zIndex from "@mui/material/styles/zIndex";
import React from "react";

const SpfScreen = (props) => {
    const backgroundStyles = {
        position: 'absolute',
        inset: '0px',
        width: '100vw',
        pointerEvents: 'none',
        zIndex:'-1',
        background: `${props.backgroundImage ? 'url(' + props.backgroundImage + ')' : props.backgroundColor ? props.backgroundColor : 'transparent'} center center / cover no-repeat`,
        //how to handle background video
    }

    return (
        <section className={`bronte-body screen ${props.modifier}`}>
            <div className="screen-background-container" style={backgroundStyles}/>
            <div className="stage-container">
                <div className="stage-box">
                    <div className="spf-rce-stage">
                        {props.children}
                    </div>
                </div>
            </div>
        </section>
    )
}
export default SpfScreen