/** @format */

import OptionsMenu from '../shared/OptionsMenu';
import Switch from '../shared/Switch';
import { IconButton, TableCell, TableRow, Typography } from '@mui/material';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { Link, useNavigate } from 'react-router-dom';

const ListTableItem = (props) => {

	const navigate = useNavigate();
	const menuOptions = [
		{ option: 'Edit...' },
		{ option: 'Copy' },
		{ option: 'Delete' },
		{ option: 'Assignment settings' },
		{
			option: 'Student settings',
			separator: true,
		},
		{ option: 'Question details' },
		{ option: 'Preview' },
		{ option: 'Print' },
	];

	const openAssignment = (slug) => {
		navigate(process.env.PUBLIC_URL + '/assignmentOverview',
			{content: slug}
		)
	}

	

	return (
		<TableRow className='assignment-row'>
			<TableCell
				className='draggable'
				width='5%'
			>
				<IconButton>
					<DragIndicatorIcon />
				</IconButton>
			</TableCell>
			<TableCell
				scope='row'
				className='row-info'
			>
				<Link to={'/assignmentOverview?content='+props.assignment.slug} className='assignment-title'
				>
					{props.assignment.title}
				</Link>
				<p
					className='metadata assignment-metadata'
				>
					<span>{props.assignment.category}</span> |
					<span>{props.assignment.points} pts</span> 
					{/* | */}
					{props.assignment.tags?.map((tagText) => (
						<span className='assignment-tag'>{tagText}</span>
					))}
				</p>
			</TableCell>

			<TableCell className='start-date'>
				<p className='cell-label'>
					Start:
					</p>
				<p className='metadata'>
					{props.assignment.startDate}
				</p>
			</TableCell>
			<TableCell className='row-date'>
				<p className='cell-label'>
					Due:
				</p>
				<p className='metadata'>
					{props.assignment.endDate}
				</p>
			</TableCell>
			<TableCell className='assign-status'>
				<Switch checked={props.assignment.status === 'Assigned'} onChange={() => console.log("toggle")} />
			</TableCell>
			<TableCell
				className='assgn-options'
				width='7%'
			>
				<OptionsMenu
					title={props.assignment.title}
					options={menuOptions}
				/>
			</TableCell>
		</TableRow>
	);
};

export default ListTableItem;
