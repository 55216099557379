import React from "react";

const SpfColumn = (props) => {
    const styles = {
        maxWidth: props.size
    }

    return (
        <div className={`spf-rce-column`} style={props.size ? styles : null}>
            {props.children}
        </div>           
    )
}
export default SpfColumn