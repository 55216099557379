
import PlayerFooter from "./PlayerFooter";
import Accordion from '../shared/Accordion';
import SplitPane from 'react-split-pane';
// import './assets/css/bronte_theme2.css';
import { forwardRef, useEffect, useRef } from "react";
import NarrativeContent from "./NarrativeContent";
import influenza from '../assets/data/influenzaContent.json';
import SpfScreen from "../content/SpfScreen";
import SpfSection from "../content/SpfSection";
import SpfRow from "../content/SpfRow";
import SpfColumn from "../content/SpfColumn";
import SpfElementText from "../content/SpfElementText";
import SpfElementImage from "../content/SpfElementImage";
import SpfCarousel from "../content/SpfCarousel";
import SpfFlipcard from "../content/SpfFlipcard";

const PlayerContent = forwardRef((props, ref) => {
    const {iFrameRef, ref1} = ref;
    const iframe = useRef();

    // useEffect(() => {
    //     if (props.data.content[0].type === 'applet') {
    //         let styleTag = document.createElement("style");
    //             // document.head.appendChild(styleTag);
    //             styleTag.textContent = `#playerBody .footer {display:none;}`;
                
    //         // window.addEventListener("message", receiveMessage, false);
    //         // iframe.current.contentWindow.postMessage(styleTag, "*")
    //         console.log(styleTag)
    //         if (iframe.current) {
    //             iframe.current.addEventListener('contentloaded')
    //             // var headID = document.getElementsByTagName("head");         
    //             // console.log(iframe.current.contentWindow.document)
    //             // var y = iframe.current.contentWindow.document;
    //             // y.body.style.backgroundColor = "red";
    //             var buts = iframe.current.contentDocument.body.querySelector("footer")
    //             console.log(buts)
    //             // iframe.current.contentWindow.document.documentElement.head.appendChild(styleTag)
    //         }
    //     }
    // },[props.data])

    const hideFooterInIframe = () => {
        iframe.current.contentWindow.document.body.style.backgroundColor = "red"
    }

    let random = Math.random().toFixed(4);
    
    // console.log(props.totalItems)
    
    return(
        <article className="assignment-content">
            <section className="item-content" id="assignmentContent" lang="en">
                {props.data.screens?.map((screen, i) => {
                   return (
                    <SpfScreen modifier={screen.modifier} key={screen.id} backgroundImage={screen.backgroundImage} backgroundColor={screen.backgroundColor}>
                        {screen.sections.map((section,index) => {
                            return (
                                <SpfSection modifier={section.modifier}>
                                    {section.rows?.map((row,in2) => {
                                        return (
                                            <SpfRow>
                                                {row.columns?.map((column,in3) => {
                                                    return (
                                                        <SpfColumn size={column.size}>
                                                            {column.content.map((content,in4) => {
                                                                // console.log(content)
                                                                if (content.type === 'spacer') {
                                                                    return <div className='spf-rce-spacer'/>
                                                                }
                                                                else if (content.type === 'text') {
                                                                    return (
                                                                        <SpfElementText data={content.content} modifier={content.modifier}/>
                                                                    )
                                                                } else if (content.type === 'image') {
                                                                    return (
                                                                        <SpfElementImage data={content.content} modifier={content.modifier}/>
                                                                    )
                                                                } else if (content.type === 'carousel') {
                                                                    return (
                                                                        <SpfCarousel data={content.content} />
                                                                    )
                                                                } else if (content.type === 'flipcards') {
                                                                    return (
                                                                        <SpfFlipcard data={content.content} cardHeight={content.cardHeight} />
                                                                    )
                                                                } else {
                                                                    return (<p>Need content type: {content.type}</p>)
                                                                }
                                                            })}
                                                        </SpfColumn>
                                                    )
                                                })}
                                            </SpfRow>
                                        )
                                    })}
                                </SpfSection>
                            )
                        })}
                    </SpfScreen>
                   )
                })}
                    
            </section>

        
            <PlayerFooter 
                currentItem={props.currentItem} 
                action={props.data.action} 
                nextItem={props.nextItem}
                prevItem={props.prevItem}
                answerSelected={props.answerSelected}
                checkAnswer={props.checkAnswer}
                answerCorrect={props.answerCorrect}
                itemType={props.data.type}
                // navigationOpen={props.navigationOpen}
                subcat={props.data.subcat}
                disablePrev={props.currentItem === 0 || props.demo}
                disableNext={props.totalItems === 1 || props.demo}
            /> 
        </article>
    )
})

export default PlayerContent