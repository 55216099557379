import React, {useState} from "react";
import { ExpandMoreRounded } from "@mui/icons-material";
import AssignmentCard from "../shared/AssignmentCard";

const AssignmentGroup = (props) => {
    const [open, setOpen] = useState(props.open ? props.open : !props.grouped ? true : false)
    let itemCount = -1;

    const countCompleted = (items) => {
        let count = 0;
        items.forEach(item => {
            if (item.status === 'completed') {
                count = count + 1
            }
        })
        return count
    }
    return (
        <div className="assignment-group-container">
            {props.grouped && 
            <button className="assignment-group-btn" aria-expanded={open} onClick={() => setOpen(!open)}>
                {props.data.featureImage && <div className="feature-image"><img src={props.data.featureImage} alt="" /></div>}
                <div className="assignment-info">
                    <span className="text group-title">{props.data.title}</span>
                    <ul className="item-meta-data">
                        <li>{props.data.time} min</li>
                        <li className="item-progress">
                            <div className="progress-bar" aria-hidden="true">
                                <div className="bar" style={{width: `${100 * (countCompleted(props.data.assignmentItems) / props.data.assignmentItems.length)}%`}}></div>
                            </div>
                            <div className="progress-bar-label">
                                {countCompleted(props.data.assignmentItems)+" of "+props.data.assignmentItems.length} completed
                            </div>
                        </li>
                    </ul>
                </div>
                <span className="icon">
                    <ExpandMoreRounded fontSize="large" />
                </span>
            </button> }
            <ol className={open ? "assignment-group open" : "assignment-group closed"}>
                {props.data.assignmentItems.map((item, j) => {
                    // console.log(item)
                    itemCount = itemCount + 1;
                    let overallIndex = () => props.findIndex(item.id)
                    return( 
                        <li key={`item${j}`}>
                            <AssignmentCard 
                                data={item} 
                                gotoItem={() => {props.gotoItem(overallIndex());props.navigate()}} 
                                itemCount={itemCount}
                                className="in-overview"
                            />
                        </li>
                    )
                })}
            </ol>
        </div>
    )
}
export default AssignmentGroup