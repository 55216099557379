/** @format */

import PearsonLogo from '../assets/images/logo_pearson.svg';
import { IconButton } from '@mui/material/';
import Switch from './Switch';
import UserMenu from './UserMenu';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Link } from 'react-router-dom';

const Header = (props) => {
	return (
		<header className={`app-header ${props.page.toLowerCase()}`}>
			<div className='left'>
				<Link to="../"><img
					src={PearsonLogo}
					alt=''
				/></Link>
				<h1>
					<span>{props.title ? props.title : "MyLab"}</span> 
				</h1>
			</div>
			<div className='right'>
				{/*<label htmlFor='studentSwitch'>Student view</label>

				<Switch
					id='studentSwitch'
					checked={props.studentView}
					onChange={props.changeStudentView}
	/> */}
				<IconButton>
					<HelpOutlineIcon sx={{ width: '24px', height: '24px' }} />
				</IconButton>
				{/* <IconButton sx={{ ml: '-8px', position: 'relative' }}>
					<NotificationsNoneIcon sx={{ width: '30px', height: '30px' }} />
					<span className='badge'>3</span>
				</IconButton> */}

				<UserMenu />
			</div>
		</header>
	);
};

export default Header;
