import React from "react";

const SpfSection = (props) => {

    return (
        <div className={`spf-rce-section ${props.modifier}`}>
            <div className="spf-rce-section-background-wrapper">
                <div className="spf-rce-section-content">
                    {props.children}
                </div>
            </div>
        </div>           
    )
}
export default SpfSection