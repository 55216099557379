import React, { useState } from "react";

const SpfFlipcard = (props) => {


    const Card = (props) => {
        const [active, setActive] = useState(false);
        let card = props.card;
        console.log(card)

        const frontStyles = {
            backgroundColor: card.front.backgroundColor ? card.front.backgroundColor : 'rgba(255,255,255,1)',
            backgroundImage: card.front.backgroundImage ? 'url(' + card.front.backgroundImage + ')' : 'none',
            minHeight: props.cardHeight ? props.cardHeight : '250px'
        }
        const backStyles = {
            backgroundColor: card.back.backgroundColor ? card.back.backgroundColor : 'rgba(255,255,255,1)',
            backgroundImage: card.back.backgroundImage ? 'url(' + card.back.backgroundImage + ')' : 'none',
            minHeight: props.cardHeight ? props.cardHeight : '250px'
        }
        return (
            <li className="card">
                <div class={active ? "card-container active" : "card-container"} role="button" tabindex="0" onClick={() => setActive(!active)}>
                    <section aria-hidden={active} class="front cover" style={frontStyles}>
                        
                        <div class="inner">
                            {card.front.text}
                        </div>
                    </section>

                    <section aria-hidden={!active} class="back cover" style={backStyles} >
                        
                        <div class="inner">
                            {card.back.text}
                        </div>
                    </section>
                </div>
            </li>
        )
    }

    return (
        <div className="spf-rce-element spf-element-plugin spf-rce-flipcard">
            <div className="cards-wrapper">
                <ol className="cards-container">
                    {props.data.map((card, index) => {
                        console.log(card)
                        return (
                            <Card card={card} key={"flipcard"+index}/>
                        )
                    })}
                
                </ol>
            </div>
        </div>
    )
}
export default SpfFlipcard