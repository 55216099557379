import React, { useEffect, useState } from "react";
import { showHide } from "../assets/contentHelpers";
const NarrativeContent = (props) => {
    const [link, setLink] = useState(props.link)
    

    useEffect(() => {
        setLink(props.link)
    },[props.link])

  

    return (
        <div className="bronte-content">
            <load-file src={process.env.PUBLIC_URL + link}></load-file>
        </div>
    )
}
export default NarrativeContent