import React from "react";
import pearsonLogo from "../assets/images/logo_pearson.svg";

const Sidebar = (props) => {

    return (
        <aside className="sidebar">
            {/* <div className='header-logo'>
                <img
                    src={pearsonLogo}
                    alt=''
                />
            </div> */}
            <ul className="main-menu" role="menu" tabindex="-1">
                <li className="menu-item" tabindex="-1" role="menuitem">
                    <a href="#">Home</a>
                </li>
                <li className="menu-item selected" tabindex="0" role="menuitem">
                    <a href="#">Assignments</a>
                </li>
                <li className="menu-item" tabindex="-1" role="menuitem">
                    <a href="#">Gradebook</a>
                </li>
                <li className="menu-item" tabindex="-1" role="menuitem">
                    <a href="#">eTextbook</a>
                </li>
                <li className="menu-item" tabindex="-1" role="menuitem">
                    <a href="#">Resources</a>
                </li>
            </ul>
        
        </aside>
    )
}
export default Sidebar