
import PlayerFooter from "./PlayerFooter";
import Accordion from '../shared/Accordion';
import SplitPane from 'react-split-pane';
// import './assets/css/bronte_theme2.css';
import { forwardRef, useEffect, useRef } from "react";
import NarrativeContent from "./NarrativeContent";
import { useInView } from "react-intersection-observer";
import VirtualScrollChild from "./VirtualScrollChild";
import { Fade } from "react-reveal";

const PlayerContentScroll = forwardRef((props, ref) => {
    const [view, inView] = useInView();
    const {iFrameRef, ref1} = ref;
    const iframe = useRef();

    // useEffect(() => {
    //     if (props.data.content[0].type === 'applet') {
    //         let styleTag = document.createElement("style");
    //             // document.head.appendChild(styleTag);
    //             styleTag.textContent = `#playerBody .footer {display:none;}`;
                
    //         // window.addEventListener("message", receiveMessage, false);
    //         // iframe.current.contentWindow.postMessage(styleTag, "*")
    //         console.log(styleTag)
    //         if (iframe.current) {
    //             iframe.current.addEventListener('contentloaded')
    //             // var headID = document.getElementsByTagName("head");         
    //             // console.log(iframe.current.contentWindow.document)
    //             // var y = iframe.current.contentWindow.document;
    //             // y.body.style.backgroundColor = "red";
    //             var buts = iframe.current.contentDocument.body.querySelector("footer")
    //             console.log(buts)
    //             // iframe.current.contentWindow.document.documentElement.head.appendChild(styleTag)
    //         }
    //     }
    // },[props.data])

    const hideFooterInIframe = () => {
        iframe.current.contentWindow.document.body.style.backgroundColor = "red"
    }

    let random = Math.random().toFixed(4);
    
    // console.log(props.totalItems)
    
    return(
        <article className="assignment-content">
            <section className="item-content" id="assignmentContent">
                {props.data?.map((contentPart, i) => {
                   
                    return(
                        <> 
                            <VirtualScrollChild key={`content${i}`} trigger={() => {props.gotoItem(i);console.log(i)}}>
                                    <Fade duration={1000}>
                                        <NarrativeContent link={contentPart.content[0].link} />
                                        </Fade>
                            </VirtualScrollChild>
                        </>
                    ) 
                })}
                    
            </section>

        
            {/* <PlayerFooter 
                currentItem={props.currentItem} 
                action={props.data.action} 
                nextItem={props.nextItem}
                prevItem={props.prevItem}
                answerSelected={props.answerSelected}
                checkAnswer={props.checkAnswer}
                answerCorrect={props.answerCorrect}
                itemType={props.data.type}
                // navigationOpen={props.navigationOpen}
                subcat={props.data.subcat}
                disablePrev={props.currentItem === 0 || props.demo}
                disableNext={props.totalItems === 1 || props.demo}
            />  */}
        </article>
    )
})

export default PlayerContentScroll